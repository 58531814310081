@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'BodoniFLF';
  src: url('/public/fonts/BodoniFLF-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BodoniFLF';
  src: url('/public/fonts/BodoniFLF-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'BodoniFLF';
  src: url('/public/fonts/BodoniFLF-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'BodoniFLF';
  src: url('/public/fonts/BodoniFLF-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Garet";
  src: url("/public/fonts/Garet-Book.otf") format("truetype");
  font-weight: normal; 
  font-style: normal;
}
